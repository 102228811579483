<template>
    <div id="web_bg">
        <div class="web-title">
            <el-image class="all-image" :src="require('@/assets/image/logo.png')"></el-image>
        </div>
        
        <div class="login-main">
            <div class="login-right-txt">
                <router-link style="visibility:hidden" class="link" to="login">登录</router-link>
                <div>重置密码</div>
                <router-link class="link" to="login">登录</router-link>
            </div>
            <div>
                <div :class="[selectInputStatus == 1?'login-slelct':'']" class="login-user-all login-form">
                    <img style=" width: 18px;height: 18px;" src="../../assets/image/user.png" />
                    <!-- <el-dropdown trigger="click" @command="handleCompanyChange">
                        <span class="el-dropdown-link">
                            +{{ruleForm.qu_num}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,key) in quList" :command="item" :key="key">{{item.qu_num}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
                    <input 
                    style="background-color: transparent;border:0;color:#fff" 
                    @blur.prevent="selectInputStatus = 0" 
                    @focus="selectInputStatus = 1" 
                    v-model="ruleForm.username" 
                    placeholder="手机号" />
                </div>
                <div :class="[selectInputStatus == 2?'login-slelct':'']"  class="login-pwd-all login-form">
                    <img src="../../assets/image/pwd.png" />
                    <input 
                    style="background-color: transparent;border:0;color:#fff;" 
                    @blur.prevent="selectInputStatus = 0" 
                    @focus="selectInputStatus = 2" 
                    type="password" 
                    v-model="ruleForm.password"  
                    @keyup.enter="loginClick" 
                    placeholder="密码" />
                </div>
                <div :class="[selectInputStatus == 3?'login-slelct':'']"  class="login-pwd-all login-form">
                    <img src="../../assets/image/pwd.png" />
                    <input 
                    style="background-color: transparent;border:0;color:#fff;" 
                    @blur.prevent="selectInputStatus = 0" 
                    @focus="selectInputStatus = 3" 
                    type="password" 
                    v-model="ruleForm.surepassword"  
                    @keyup.enter="loginClick" 
                    placeholder="确认密码" />
                </div>
            </div>
            <el-button class="logon-button" :loading="loginStatus" @click="loginClick">确 定</el-button>
        </div>
    </div>
</template>
<script>
import {resetpwd,getqu} from '@/api/index'
export default {
    name:'Register',
    data(){
        return {
            ruleForm: {
                username: '',
                password: '',
                surepassword:'',
                qu_num:''
            },
            loginStatus:false,
            selectInputStatus:0,
        };
        
    },
    created(){
        // this.getquList()
    },
    
    methods:{
        // 获取区号
        getquList(){
            getqu().then(res=>{
                this.quList = res.data
                this.ruleForm.qu_num = res.data[0].qu_num
            })
        },
        // 区号点击
        handleCompanyChange(command){
            this.ruleForm.qu_num = command.qu_num
        },
        loginClick(){
            if(this.ruleForm.username == '' || this.ruleForm.password == ''){
                this.$message.error('请输入填写完整信息');
                return
            }
            if(this.ruleForm.password  != this.ruleForm.surepassword){
                this.$message.error('两次密码输入不一致')
                return
            }
            // if (!(/^1[3456789]\d{9}$/.test(this.ruleForm.username) )
            // || /^[23456789][0135678][023456789]\d{7}$/.test(this.ruleForm.username)){
            //     this.$message.error('手机号格式不正确');
            //     return
            // }
            this.loginStatus = true
            resetpwd(this.ruleForm).then(res=>{
                if(res.code){
                    this.$message({
                        type:'success',
                        message:res.msg
                    })
                    setTimeout(() => {
                        this.$router.replace('/login')    
                    }, 2000);
                }
                this.loginStatus = false
            })
        },
        selectInput(id){
            this.selectInputStatus = id
        }

      
    }
}
</script>
<style scoped>
::-webkit-input-placeholder { color:#FFFFFF; }
::-moz-placeholder { color:#FFFFFF; } /* firefox 19+ */
:-ms-input-placeholder { color:#FFFFFF; } /* ie */
input:-moz-placeholder { color:#FFFFFF; }
input:focus{
	outline: medium;
}
#web_bg{
  position:fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  z-index:-10;
  zoom: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-image: url('../../assets/image/login-bg.png');
  /* background-size: 100% 100%; */
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.login-main{
    background-image: url('../../assets/image/login-main.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 500px;
    height: 480px;
    z-index: 1;
    padding: 48px;
}
.login-right-txt{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
    text-align: center;
    font-size: 38px;
    font-weight: 400;
    color: #FFFFFF;
}
.login-fun{
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-size: 20px;
   color: #00B8FF;
   margin: 24px 0 42px 0;

}
.login-form{
    height: 56px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    border: 2px solid #004B77;
}
.login-form img{
    width: 22px;
    height: 26px;
    margin: 0 20px;
}
.login-form input{
    font-size: 22px;
}
.login-pwd-all{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
}
.logon-button{
    background: linear-gradient(180deg, #00b8ff, #0177e4);
    border: none;
    height: 60px;
    width: 100%;
    font-size: 26px;
    color: #FFFFFF;
}
.el-dropdown-link{
    color: #fff;
    padding: 0 20px;
}
.login-user-all{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 34px;
}
.login-slelct{
    background: none;
    border: 2px solid #00D2FF;
}
.all-image{
    margin: 84px 0 90px 94px;
    width: 430px;
}
.web-title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.link{
    color: #00B8FF;
    text-decoration: none;
    font-size: 20px;
}
</style>